import Blocks from 'components/blocks/blocks';
import Icon from 'components/icon/icon';
import SEO from 'components/seo/seo';
import TableContents from 'components/tableContents/tableContents.component';
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { pageContextProps } from 'utils/props.utils';
import './paper.template.scss';
import { handlePageLoaded } from 'components/trans/transLink';

interface Props {
  pageContext: pageContextProps;
  data: {
    paper: {
      blocks: any;
    };
  };
}

const PaperTemplate: React.FC<Props> = (props) => {
  const isBrowser = typeof window !== 'undefined';
  const _isDesktop = isBrowser && window.innerWidth > 980;

  const [ctOpen, setCtOpen] = useState<boolean>(false);

  useEffect(() => {
    const isBrowser = typeof window !== 'undefined';
    const _isDesktop = isBrowser && window.innerWidth > 980;

    if (_isDesktop) {
      setCtOpen(true);
    }
    handlePageLoaded();
  }, []);

  return (
    <div className="paper-template">
      <section className="section" id="section">
        <SEO {...props.pageContext.seo} />
        <div className="section-header">
          <h5 className="section-header__title">IWP Policy Paper</h5>
        </div>
        <div className="paper-template__ui">
          <button onClick={() => setCtOpen(true)} className="paper-template__ui-button">
            <Icon icon="list2" size="20" />
          </button>
        </div>
        <div className="section-content paper-template-content" onClick={() => setCtOpen(false)}>
          <h1>{props.pageContext.title}</h1>
          <Blocks blocks={props.data.paper.blocks} enumHeadlines glossary={props.pageContext.glossary} />
        </div>
        <TableContents content={props.data.paper.blocks} onClose={() => setCtOpen(false)} isOpen={ctOpen} />
      </section>
    </div>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!) {
    paper: wpPaper(id: { eq: $id }) {
      blocks {
        name
        order
        ...AnchorLinksBlock
        ...AuthorLineBlock
        ...ContactBlock
        ...DataWrapperBlock
        ...FormBlock
        ...ForschungDetailBlock
        ...GalleryBlock
        ...GraphBlock
        ...HeadingBlock
        ...HeroBlock
        ...HeroSmallBlock
        ...ImageBlock
        ...InfoBoxBlock
        ...LinksBlock
        ...ListBlock
        ...MultiColumnTextBlock
        ...PageFooterTextBlock
        ...ParagraphBlock
        ...PartnerBlock
        ...PostWithTagBlock
        ...PullQuoteBlock
        ...ReadMoreBlock
        ...SidTeasersBlock
        ...SourceBlock
        ...TeamGridBlock
        ...TeamGridSimpleBlock
        ...TopicsBlock
        ...ValuesBlock
        ...VideoIframeBlock
        ...ShareButtonsBlock
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default PaperTemplate;
