import React, { useEffect, useState, MouseEvent } from 'react';
import parse from 'html-react-parser';
import './tableContents.component.scss';
import classNames from 'classnames';
import { stripTags, stripTagsForID } from 'utils/striptags.utils';
import gsap from 'gsap';
import { useLocation } from '@reach/router';

interface Props {
  content: any[];
  numbering?: string;
  isOpen: boolean;
  onClose: () => void;
}

const TableContents: React.FC<Props> = (props) => {
  const [headings, setHeadings] = useState<any[]>([]);

  const location = useLocation();

  // set the headings
  useEffect(() => {
    const itemsFiltered = props.content.filter((item) => item.name === 'core/heading');
    //
    const _headings: any[] = [];
    itemsFiltered.forEach((item, key) => {
      if (item.attributes.level === 2) {
        _headings.push([item, []]);
      } else if (item.attributes.level === 3) {
        const firstLength = _headings.length - 1;
        const secondLength = _headings[firstLength].length - 1;
        _headings[firstLength][secondLength].push(item);
      }
    });
    setHeadings(_headings);
  }, []);

  const scrollToPosition = (id: string) => {
    const element = document.getElementById(id);
    const section = document.getElementById('section');
    if (!!element && !!section) {
      let scrollTo = element.offsetTop + section.offsetTop + 30;
      if (window.innerWidth < 768) {
        scrollTo -= 48;
      }
      gsap.to(window, {
        duration: 1,
        scrollTo: scrollTo,
      });
    }
  };

  // handle item click
  const handleItemClick = (e: MouseEvent) => {
    const target = e.currentTarget;
    if (!!target) {
      const id = target.getAttribute('data-id');
      if (!!id) {
        scrollToPosition(id);
        // const element = document.getElementById(id);
        // const section = document.getElementById('section');
        // if (!!element && !!section) {
        //   let scrollTo = element.offsetTop + section.offsetTop + 30;
        //   if (window.innerWidth < 768) {
        //     scrollTo -= 48;
        //   }
        //   gsap.to(window, {
        //     duration: 1,
        //     scrollTo: scrollTo,
        //   });
        // }
      }
    }
    props.onClose();
  };

  useEffect(() => {
    let hash = location.hash;
    if (!!hash) {
      hash = decodeURI(hash.replace('#', ''));
      setTimeout(() => {
        scrollToPosition(hash);
        props.onClose();
      }, 1000);
    }
  }, [location]);

  return (
    <div className={classNames('table-contents', { 'is-open': props.isOpen })}>
      <button className="table-contents__close" onClick={props.onClose} />
      <h5>Inhaltsverzeichnis</h5>
      <ul className="table-contents-list">
        {headings.map((item, key) => (
          <li key={key} className="table-contents-list__item">
            {item.map((item2: any, key2: number) => {
              if (!!item2.attributes) {
                let string = stripTags(item2.attributes.content);
                !!item2.attributes.numbering && (string = `${item2.attributes.numbering} ${string}`);
                return (
                  <div
                    key={key2}
                    className="table-contents-list__inner table-contents-list__level-1"
                    onClick={handleItemClick}
                    data-id={stripTagsForID(string)}
                  >
                    {string}
                  </div>
                );
              } else {
                return (
                  <ul key={key2}>
                    {item2.map((item3: any, key3: number) => {
                      if (!!item3.attributes) {
                        let string = stripTags(item3.attributes.content);
                        !!item3.attributes.numbering && (string = `${item3.attributes.numbering} ${string}`);
                        return (
                          <li key={key3}>
                            <div
                              className="table-contents-list__inner table-contents-list__level-2"
                              onClick={handleItemClick}
                              data-id={stripTagsForID(string)}
                            >
                              {string}
                            </div>
                          </li>
                        );
                      }
                    })}
                  </ul>
                );
              }
            })}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TableContents;
